var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard"},[_c('div',{staticClass:"dashboard__container"},[_vm._m(0),_c('div',{staticClass:"dashboard__container--body"},[(!_vm.venues || _vm.venues.length == 0)?_c('Loader'):_vm._e(),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.venues,"styleClass":"vgt-table condensed","search-options":{
            enabled: false,
            placeholder: 'Search this table',
          },"pagination-options":{
            enabled: true,
            mode: 'records',
            perPage: 20,
          }},on:{"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'visible')?_c('span',[(props.row.visible)?_c('i',{staticClass:"fa-solid fa-check",staticStyle:{"color":"green"}}):_vm._e()]):(props.column.field == 'client')?_c('span',_vm._l((props.row.client),function(c,index){return _c('button',{key:index,staticClass:"btn chip mr-2"},[_vm._v(_vm._s(c.title))])}),0):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard__container--header"},[_c('h1',[_vm._v("My Venues")])])}]

export { render, staticRenderFns }